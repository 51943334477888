import cardone from "../cardone.png";
import cardtwo from "../cardtwo.png";
import cardthree from "../cardthree.png";
import cardbig from "../cardbig.png";
import { Link } from "react-router-dom";
export default function HowDoesItWork() {
    return (
        <div className="w-full  px-[20px] py-[40px] xl:px-[40px] flex flex-col gap-[20px]">
            <div className="flex justify-between">
                <div className="flex flex-col gap-[10px]">
                    <p className="font-bold xl:text-[2.38rem] text-[1.50rem]">
                        How Does It <span className="font-normal text-[#1DBF73]">Work</span>?
                    </p>
                    <p className="lg:text-[0.94rem] text-[0.75rem]">
                        with just few simple steps, you can issue, sell and fullfill Promise Bonds/ Here's the journey
                    </p>
                </div>
                <Link to="/howitworks" className=" underline lg:text-[0.94rem] text-[0.75rem] mt-[40px] lg:block hidden text-center">See more</Link>
            </div>
            <div className="grid gap-[20px] md:grid-cols-3 grid-cols-1">
                <div className="bg-white p-[20px] flex flex-col gap-[10px] rounded-[20px]">
                    <div className="w-[40px] h-[40px] rounded-[100%] bg-[#F6F6F6]">
                        <svg  className="w-[35px] h-[35px] sm:w-[35px] sm:h-[35px] md:w-[40px] md:h-[40px] lg:w-[48px] lg:h-[48px]" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_259)">
                                <path d="M9.81498 31.055H11.2039V37.5365C11.2039 38.3035 11.8257 38.9253 12.5928 38.9253C13.3598 38.9253 13.9816 38.3035 13.9816 37.5365V31.7494L22.315 35.2216V35.6846C22.315 36.4516 22.9368 37.0735 23.7039 37.0735C24.4709 37.0735 25.0928 36.4516 25.0928 35.6846V18.092C25.0928 17.325 24.4709 16.7031 23.7039 16.7031C22.9368 16.7031 22.315 17.325 22.315 18.092V18.555L12.315 22.7216H9.81498C7.51748 22.7216 5.64832 24.5908 5.64832 26.8883C5.64832 29.1858 7.51748 31.055 9.81498 31.055ZM13.9816 25.0365L22.315 21.5642V32.2124L13.9816 28.7402V25.0365ZM9.81498 25.4994H11.2039V28.2772H9.81498C9.04915 28.2772 8.42609 27.6541 8.42609 26.8883C8.42609 26.1225 9.04915 25.4994 9.81498 25.4994Z" fill="#1F4B3F" />
                                <path d="M29.2599 22.7221H40.371C41.138 22.7221 41.7599 22.1003 41.7599 21.3332C41.7599 20.5662 41.138 19.9443 40.371 19.9443H29.2599C28.4928 19.9443 27.871 20.5662 27.871 21.3332C27.871 22.1003 28.4928 22.7221 29.2599 22.7221Z" fill="#1F4B3F" />
                                <path d="M29.2599 28.2778H40.371C41.138 28.2778 41.7599 27.6559 41.7599 26.8889C41.7599 26.1219 41.138 25.5 40.371 25.5H29.2599C28.4928 25.5 27.871 26.1219 27.871 26.8889C27.871 27.6559 28.4928 28.2778 29.2599 28.2778Z" fill="#1F4B3F" />
                                <path d="M29.2599 33.8334H40.371C41.138 33.8334 41.7599 33.2116 41.7599 32.4446C41.7599 31.6775 41.138 31.0557 40.371 31.0557H29.2599C28.4928 31.0557 27.871 31.6775 27.871 32.4446C27.871 33.2116 28.4928 33.8334 29.2599 33.8334Z" fill="#1F4B3F" />
                                <path d="M42.3147 0.407227H5.09247C2.28442 0.407227 -0.00012207 2.69176 -0.00012207 5.49982V42.722C-0.00012207 45.5301 2.28442 47.8146 5.09247 47.8146H42.3147C45.1227 47.8146 47.4073 45.5301 47.4073 42.722V5.49982C47.4073 2.69176 45.1227 0.407227 42.3147 0.407227ZM2.77766 5.49982C2.77766 4.22343 3.81608 3.185 5.09247 3.185H33.5184V7.81463H2.77766V5.49982ZM44.6295 42.722C44.6295 43.9984 43.5911 45.0369 42.3147 45.0369H5.09247C3.81608 45.0369 2.77766 43.9984 2.77766 42.722V10.5924H44.6295V42.722ZM44.6295 7.81463H36.2962V3.185H42.3147C43.5911 3.185 44.6295 4.22343 44.6295 5.49982V7.81463Z" fill="#1F4B3F" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_259">
                                    <rect width="47.4074" height="47.4074" fill="white" transform="translate(-0.00012207 0.407227)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <span className="lg:text-[1.25rem] text-[1rem] font-semibold mt-[20px]">Sign Up</span>
                    <p className="lg:text-[0.94rem] text-[0.75rem] text-[#6B7177]">Join our platform in minutes with email and phone verification.</p>

                </div>

                <div className="bg-white p-[20px] flex flex-col gap-[10px] rounded-[20px]">
                    <div className="w-[40px] h-[40px] rounded-[100%] bg-[#F6F6F6]">
                        <svg className="w-[35px] h-[35px] sm:w-[35px] sm:h-[35px] md:w-[40px] md:h-[40px] lg:w-[48px] lg:h-[48px]" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_259)">
                                <path d="M9.81498 31.055H11.2039V37.5365C11.2039 38.3035 11.8257 38.9253 12.5928 38.9253C13.3598 38.9253 13.9816 38.3035 13.9816 37.5365V31.7494L22.315 35.2216V35.6846C22.315 36.4516 22.9368 37.0735 23.7039 37.0735C24.4709 37.0735 25.0928 36.4516 25.0928 35.6846V18.092C25.0928 17.325 24.4709 16.7031 23.7039 16.7031C22.9368 16.7031 22.315 17.325 22.315 18.092V18.555L12.315 22.7216H9.81498C7.51748 22.7216 5.64832 24.5908 5.64832 26.8883C5.64832 29.1858 7.51748 31.055 9.81498 31.055ZM13.9816 25.0365L22.315 21.5642V32.2124L13.9816 28.7402V25.0365ZM9.81498 25.4994H11.2039V28.2772H9.81498C9.04915 28.2772 8.42609 27.6541 8.42609 26.8883C8.42609 26.1225 9.04915 25.4994 9.81498 25.4994Z" fill="#1F4B3F" />
                                <path d="M29.2599 22.7221H40.371C41.138 22.7221 41.7599 22.1003 41.7599 21.3332C41.7599 20.5662 41.138 19.9443 40.371 19.9443H29.2599C28.4928 19.9443 27.871 20.5662 27.871 21.3332C27.871 22.1003 28.4928 22.7221 29.2599 22.7221Z" fill="#1F4B3F" />
                                <path d="M29.2599 28.2778H40.371C41.138 28.2778 41.7599 27.6559 41.7599 26.8889C41.7599 26.1219 41.138 25.5 40.371 25.5H29.2599C28.4928 25.5 27.871 26.1219 27.871 26.8889C27.871 27.6559 28.4928 28.2778 29.2599 28.2778Z" fill="#1F4B3F" />
                                <path d="M29.2599 33.8334H40.371C41.138 33.8334 41.7599 33.2116 41.7599 32.4446C41.7599 31.6775 41.138 31.0557 40.371 31.0557H29.2599C28.4928 31.0557 27.871 31.6775 27.871 32.4446C27.871 33.2116 28.4928 33.8334 29.2599 33.8334Z" fill="#1F4B3F" />
                                <path d="M42.3147 0.407227H5.09247C2.28442 0.407227 -0.00012207 2.69176 -0.00012207 5.49982V42.722C-0.00012207 45.5301 2.28442 47.8146 5.09247 47.8146H42.3147C45.1227 47.8146 47.4073 45.5301 47.4073 42.722V5.49982C47.4073 2.69176 45.1227 0.407227 42.3147 0.407227ZM2.77766 5.49982C2.77766 4.22343 3.81608 3.185 5.09247 3.185H33.5184V7.81463H2.77766V5.49982ZM44.6295 42.722C44.6295 43.9984 43.5911 45.0369 42.3147 45.0369H5.09247C3.81608 45.0369 2.77766 43.9984 2.77766 42.722V10.5924H44.6295V42.722ZM44.6295 7.81463H36.2962V3.185H42.3147C43.5911 3.185 44.6295 4.22343 44.6295 5.49982V7.81463Z" fill="#1F4B3F" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_259">
                                    <rect width="47.4074" height="47.4074" fill="white" transform="translate(-0.00012207 0.407227)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <span className="lg:text-[1.25rem] text-[1rem] font-semibold mt-[20px]">Issue Promise Bonds</span>

                    <p className="lg:text-[0.94rem] text-[0.75rem] text-[#6B7177]">Describe your mission, set a price, and get operator approval.</p>
                </div>


                <div className="bg-white p-[20px] flex flex-col gap-[10px] rounded-[20px]">
                    <div className="w-[40px] h-[40px] rounded-[100%] bg-[#F6F6F6]">
                        <svg className="w-[35px] h-[35px] sm:w-[35px] sm:h-[35px] md:w-[40px] md:h-[40px] lg:w-[48px] lg:h-[48px]" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_259)">
                                <path d="M9.81498 31.055H11.2039V37.5365C11.2039 38.3035 11.8257 38.9253 12.5928 38.9253C13.3598 38.9253 13.9816 38.3035 13.9816 37.5365V31.7494L22.315 35.2216V35.6846C22.315 36.4516 22.9368 37.0735 23.7039 37.0735C24.4709 37.0735 25.0928 36.4516 25.0928 35.6846V18.092C25.0928 17.325 24.4709 16.7031 23.7039 16.7031C22.9368 16.7031 22.315 17.325 22.315 18.092V18.555L12.315 22.7216H9.81498C7.51748 22.7216 5.64832 24.5908 5.64832 26.8883C5.64832 29.1858 7.51748 31.055 9.81498 31.055ZM13.9816 25.0365L22.315 21.5642V32.2124L13.9816 28.7402V25.0365ZM9.81498 25.4994H11.2039V28.2772H9.81498C9.04915 28.2772 8.42609 27.6541 8.42609 26.8883C8.42609 26.1225 9.04915 25.4994 9.81498 25.4994Z" fill="#1F4B3F" />
                                <path d="M29.2599 22.7221H40.371C41.138 22.7221 41.7599 22.1003 41.7599 21.3332C41.7599 20.5662 41.138 19.9443 40.371 19.9443H29.2599C28.4928 19.9443 27.871 20.5662 27.871 21.3332C27.871 22.1003 28.4928 22.7221 29.2599 22.7221Z" fill="#1F4B3F" />
                                <path d="M29.2599 28.2778H40.371C41.138 28.2778 41.7599 27.6559 41.7599 26.8889C41.7599 26.1219 41.138 25.5 40.371 25.5H29.2599C28.4928 25.5 27.871 26.1219 27.871 26.8889C27.871 27.6559 28.4928 28.2778 29.2599 28.2778Z" fill="#1F4B3F" />
                                <path d="M29.2599 33.8334H40.371C41.138 33.8334 41.7599 33.2116 41.7599 32.4446C41.7599 31.6775 41.138 31.0557 40.371 31.0557H29.2599C28.4928 31.0557 27.871 31.6775 27.871 32.4446C27.871 33.2116 28.4928 33.8334 29.2599 33.8334Z" fill="#1F4B3F" />
                                <path d="M42.3147 0.407227H5.09247C2.28442 0.407227 -0.00012207 2.69176 -0.00012207 5.49982V42.722C-0.00012207 45.5301 2.28442 47.8146 5.09247 47.8146H42.3147C45.1227 47.8146 47.4073 45.5301 47.4073 42.722V5.49982C47.4073 2.69176 45.1227 0.407227 42.3147 0.407227ZM2.77766 5.49982C2.77766 4.22343 3.81608 3.185 5.09247 3.185H33.5184V7.81463H2.77766V5.49982ZM44.6295 42.722C44.6295 43.9984 43.5911 45.0369 42.3147 45.0369H5.09247C3.81608 45.0369 2.77766 43.9984 2.77766 42.722V10.5924H44.6295V42.722ZM44.6295 7.81463H36.2962V3.185H42.3147C43.5911 3.185 44.6295 4.22343 44.6295 5.49982V7.81463Z" fill="#1F4B3F" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_259">
                                    <rect width="47.4074" height="47.4074" fill="white" transform="translate(-0.00012207 0.407227)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <span className="lg:text-[1.25rem] text-[1rem] font-semibold mt-[20px]">Promote & Sell</span>

                    <p className="lg:text-[0.94rem] text-[0.75rem] text-[#6B7177]">Share your bond on social media and start earning.</p>
                </div>

            </div>
            <Link to="/howitworks" className=" underline lg:text-[0.94rem] text-[0.75rem]  lg:hidden block text-center">See more</Link>

        </div>
    )
}